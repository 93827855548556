import { Fragment, useContext, useMemo } from 'react';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router';
import {
  Sidebar,
  SidebarItem,
  useElementClass,
} from '@faxi/web-component-library';

import { ViewOnlyBanner } from 'components';
import { AuthContext } from 'store';
import { appUri } from 'config';
import { removeURLPlaceholderRegex } from 'utils';
import { INameExtended } from 'components/Icon';
import { credentialService } from 'services';

const mapPermissionToPath: Map<string, (keyof typeof appUri)[]> = new Map([
  [
    'community_management_',
    [
      'COMMUNITIES',
      'COMMUNITIES_CARPARK',
      'COMMUNITIES_JOURNEYS',
      'COMMUNITIES_CARPARK_SPACES',
      'COMMUNITIES_ADMINS',
      'COMMUNITIES_JOURNEY',
      'COMMUNITIES_JOURNEY_MAP',
      'COMMUNITIES_JOURNEY_DRIVER_TRAXES',
      'COMMUNITIES_JOURNEY_PASSENGER_TRAXES',
    ],
  ],
  [
    'journeys_',
    [
      'JOURNEYS',
      'JOURNEY_MAP',
      'JOURNEY',
      'JOURNEYS_DRIVER_TRAXES',
      'JOURNEYS_PASSENGER_TRAXES',
    ],
  ],
  [
    'service_account_',
    ['SETTINGS_SERVICE_ACCOUNTS_KEYS', 'SETTINGS_SERVICE_ACCOUNTS_KEYS_KEY'],
  ],
  [
    'market_settings_',
    ['MARKET_SETTINGS_PLATFORM_DETAILS', 'MARKET_SETTINGS_APP_SETTINGS'],
  ],
  ['users_', ['USERS', 'USERS_FEEDBACK']],
  ['team_management_', ['TEAM_MANAGEMENT']],
]);

const sidebarMainItems: SidebarItem<INameExtended>[] = [
  {
    name: 'Dashboard',
    url: appUri.DASHBOARD,
    id: 'nav_dashboard',
    iconName: 'browser',
  },
  {
    name: 'Communities',
    url: appUri.COMMUNITIES,
    id: 'nav_communities',
    iconName: 'buildings',
  },
  {
    name: 'Journeys',
    url: appUri.JOURNEYS,
    id: 'nav_journeys',
    iconName: 'route',
  },
  {
    name: 'Users',
    url: appUri.USERS,
    id: 'nav_users',
    iconName: 'users',
  },
  {
    name: 'Plans',
    url: appUri.PLANS,
    id: 'nav_plans',
    iconName: 'money-check-dollar-pen',
  },
  {
    name: 'Analytics',
    url: appUri.ANALYTICS,
    id: 'nav_analytics',
    iconName: 'chart-simple',
  },
  {
    name: 'Rewards',
    url: appUri.REWARDS,
    id: 'nav_rewards',
    iconName: 'gift',
  },
  {
    name: 'Team management',
    id: 'nav_team-management',
    url: appUri.TEAM_MANAGEMENT,
    iconName: 'user-circle',
  },
  {
    name: 'Service accounts',
    id: 'nav_service-accounts',
    url: appUri.SETTINGS_SERVICE_ACCOUNTS_KEYS,
    iconName: 'users-gear',
  },
  {
    name: 'Change logs',
    id: 'nav_change-logs',
    url: appUri.SETTINGS_CHANGE_LOGS,
    iconName: 'user-pen',
  },
  {
    name: 'Activations',
    id: 'nav_activations',
    url: appUri.ACTIVATIONS,
    iconName: 'check-to-slot',
  },
  {
    name: 'Market settings',
    id: 'nav_market-settings',
    iconName: 'cog',
    children: [
      {
        id: 'nav_market-settings_platform-details',
        name: 'Platform details',
        url: appUri.MARKET_SETTINGS_PLATFORM_DETAILS,
      },
      {
        id: 'nav_market-settings_app-settings',
        name: 'App settings',
        url: appUri.MARKET_SETTINGS_APP_SETTINGS,
      },
    ],
  },
];

const sidebarBottomItems: SidebarItem<INameExtended>[] = [
  {
    name: 'Personal details',
    url: appUri.ACCOUNT_SETTINGS_PERSONAL_DETAILS,
    id: 'nav_personal-details',
  },
];

const Root: React.FC<{
  route?: any;
}> = (props): JSX.Element => {
  const { route } = props;

  const { admin, isAuthenticated, globalLogout } = useContext(AuthContext);

  const location = useLocation();

  useElementClass('.root', 'root--is-authenticated', isAuthenticated);

  const pathnameClean = useMemo(
    () => location.pathname.replace(new RegExp('\\d+', 'g'), ''),
    [location.pathname]
  );

  const permissionStatus = useMemo<'view' | 'edit' | 'disabled'>(() => {
    let permission: string | undefined;

    let count = 0;
    let pageDoesntHavePermissions = false;

    mapPermissionToPath.forEach((value, key) => {
      if (
        value.find(
          (v) =>
            pathnameClean ===
            (typeof appUri[v] === 'string'
              ? (appUri[v] as string)
              : (appUri[v] as any)()
            ).replace(removeURLPlaceholderRegex, '')
        )
      ) {
        permission = key;
        return;
      }

      if (count === mapPermissionToPath.size - 1) {
        pageDoesntHavePermissions = true;
      }

      count++;
    });

    if (!permission || pageDoesntHavePermissions) {
      return 'disabled';
    } else {
      const adminPermission = admin?.permissions.find((p) =>
        p.name.includes(permission!)
      );

      return !adminPermission
        ? 'disabled'
        : (adminPermission.name.slice(-4) as 'view' | 'edit');
    }
  }, [admin, pathnameClean]);

  const sidebarTopItems = useMemo<SidebarItem<INameExtended>[]>(
    () => [
      {
        name: `${admin?.platform.name}`,
        url: appUri.DASHBOARD,
        id: 'nav_com1',
        image_url: '/assets/svg/market-placeholder-img.svg',
      },
    ],
    [admin]
  );

  return (
    <Fragment>
      {isAuthenticated && admin && (
        <Sidebar
          mainItems={sidebarMainItems}
          topItems={sidebarTopItems}
          bottomItems={sidebarBottomItems}
          searchInputLabel="Communities"
          onClickLogout={() => globalLogout(false)}
          logoutText="Log out"
          logoUrl="/assets/svg/kinto_join_light.svg"
          initialExpanded
          user={{
            name: [
              credentialService.user?.firstname,
              credentialService.user?.lastname,
            ]
              .join(' ')
              .trim(),
            image_url: '/assets/svg/user_circle_placeholder.svg',
          }}
          logoAlt="KINTO Join"
          logoTo={appUri.DASHBOARD}
          noResultsLabel="No results found"
          searchPlaceholder="Search"
          topItemsTitle="Platforms"
          ariaLabels={{
            ariaCloseMenu: 'Close',
            ariaCollapse: 'Collapse',
            ariaDeleteLabel: 'Delete',
            ariaExpand: 'Expand',
            ariaOpenMenu: 'Open',
          }}
        />
      )}
      {permissionStatus === 'view' && <ViewOnlyBanner />}
      {route && renderRoutes(route.routes)}
    </Fragment>
  );
};

export default Root;
