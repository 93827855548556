const appUri = {
  BASE: '/',
  ANY: '**',
  LOGIN: '/login',
  LOGGING_OUT: '/logout',

  DASHBOARD: '/dashboard',
  COMMUNITIES: '/communities',
  CREATE_COMMUNITIY: '/communities/create-community',

  // TODO: Remove this when we update to router v6
  // This is same as journey routes

  COMMUNITIES_CARPARK: (communityId = ':communityId') =>
    `/communities/${communityId}/carpark`,

  COMMUNITIES_CARPARK_SPACES: (
    communityId = ':communityId',
    carParkId = ':parkId'
  ) => `${appUri.COMMUNITIES_CARPARK(communityId)}/${carParkId}/spaces`,

  COMMUNITIES_ADMINS: (communityId = ':communityId') =>
    `/communities/${communityId}/admins`,

  COMMUNITIES_JOURNEYS: (communityId = ':communityId') =>
    `/communities/${communityId}/journey-list`,

  COMMUNITIES_JOURNEY: (
    communityId = ':communityId',
    journeyId = ':journeyId'
  ) => `${appUri.COMMUNITIES_JOURNEYS(communityId)}/journey/${journeyId}`,

  COMMUNITIES_JOURNEY_MAP: (
    communityId = ':communityId',
    journeyId = ':journeyId'
  ) => `${appUri.COMMUNITIES_JOURNEY(communityId, journeyId)}/map`,

  COMMUNITIES_JOURNEY_DRIVER_TRAXES: (
    communityId = ':communityId',
    journeyId = ':journeyId'
  ) => `${appUri.COMMUNITIES_JOURNEY_MAP(communityId, journeyId)}/driver-trax`,

  COMMUNITIES_JOURNEY_PASSENGER_TRAXES: (
    communityId = ':communityId',
    journeyId = ':journeyId',
    passengerId = ':passengerId'
  ) =>
    `${appUri.COMMUNITIES_JOURNEY_MAP(
      communityId,
      journeyId
    )}/passenger-trax/${passengerId}`,

  PLANS: '/plans',
  PLANS_CREATE: '/plans/create',

  ACTIVATIONS: '/activations',
  ACTIVATIONS_CREATE: '/activations/create',

  ANALYTICS: '/analytics',
  ANALYTICS_EKG: '/analytics/ekg',

  ACCOUNT_SETTINGS_PERSONAL_DETAILS: '/account-personal-details',

  MARKET_SETTINGS_PLATFORM_DETAILS: '/market-settings-platform-details',
  MARKET_SETTINGS_APP_SETTINGS: '/market-settings-app-settings',

  SETTINGS_SERVICE_ACCOUNTS_KEYS: '/service-accounts-hashes',
  SETTINGS_SERVICE_ACCOUNTS_KEYS_KEY: (hashId = ':hashId') =>
    `/service-accounts-hashes/${hashId}`,

  SETTINGS: '/settings',
  TEAM_MANAGEMENT: '/team-management',
  SETTINGS_CHANGE_LOGS: '/change-logs',

  TEAM_MANAGEMENT_ADMIN: (adminId = ':adminId') =>
    `/team-management/${adminId}`,

  JOURNEYS: '/journeys',
  JOURNEY: (journeyId = ':journeyId') => `/journeys/${journeyId}`,
  JOURNEY_MAP: (journeyId = ':journeyId') => `/journeys/${journeyId}/map`,
  JOURNEYS_DRIVER_TRAXES: (journeyId = ':journeyId') =>
    `/journeys/${journeyId}/map/driver-trax`,
  JOURNEYS_PASSENGER_TRAXES: (
    journeyId = ':journeyId',
    passengerId = ':passengerId'
  ) => `/journeys/${journeyId}/map/passenger-trax/${passengerId}`,

  LOGS: '/logs',
  USERS: '/users',
  USERS_FEEDBACK: '/users/feedback',
  USERS_TO_COMMUNITY: '/users/to-community',

  REWARDS: '/rewards',
};

export default appUri;
