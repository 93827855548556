import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const CommunityDetailsStyled = styled.div`
  .community-details {
    &__community_name {
      ${flex('row', 'flex-start', 'flex-start')}

      &__tooltip {
        cursor: pointer;
        ${flex('row', 'center', 'center')}
        margin-top: 36px;
        margin-left: ${theme.sizes.SIZE_32};
        
        svg {
          margin-right: ${theme.sizes.SIZE_8};
        }

        div {
          line-height: 1;
          color: var(--SHADE_1_2);
        }
      }
    }
  }

  .wcl-dropdown,
  .input-field {
    width: ${theme.sizes.SIZE_504};
  }
`;
