import { useMemo, useCallback, useState, useRef, FC, useContext } from 'react';
import {
  Table,
  Input,
  SelectOption,
  SortOption,
  useEffectSkipFirst,
  Switch,
  Button,
  useUtilities,
  useQueryParams,
  Tooltip,
  StatusElement,
  Heading,
  getColor,
  CheckboxProps,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';

import { AuthContext } from 'store';
import {
  Icon,
  InfoCard,
  NoPermissionsPlaceholder,
  TablePageLayout,
} from 'components';
import { useCallbackAsync, useColumnSettings, useTablePagination } from 'hooks';
import { apiUsers, apiPlatform } from 'modules';
import { TableUser, User } from 'models';
import { ConsentType } from 'models/TableData';
import { getConsentsFormat } from '../../utils/consents';
import credentialService from 'services/credentialService';
import { appUri, COLUMNS_SETTINGS_LABELS, dateFormat } from 'config';
import { uniqueId } from 'lodash';
import UserCommunitiesModal from './components/UserCommunitiesModal';
import { useHistory } from 'react-router';

import { INameExtended } from 'components/Icon';
import CommunitySearch from './components/CommuitySearch';
import DeactivateModal from './components/DeactivateModal';
import { NoData } from 'Global.styles';
import FiltersModal from 'components/_molecules/FiltersModal';
import FiltersButton from 'components/_molecules/FiltersButton';
import {
  deactivateReasonMessage,
  inputValidation,
  SEARCH_PARAMS,
  STATUS_MAP,
  STATUS_TRANSLATIONS,
  stringToArrayBuffer,
  USERS_FILTERS,
  statusFilterToApiParam,
  searchParamsToArray,
} from './utils';

export type Filter = {
  legendLabel: string;
  checkboxes: CheckboxProps[];
  name: string;
};

export type UserSearchParams =
  (typeof SEARCH_PARAMS)[keyof typeof SEARCH_PARAMS];

const Users: FC = () => {
  const {
    platform: { id: platformId },
  } = credentialService.user;

  const { showOverlay, hideOverlay, prompts } = useUtilities();

  const permissionsChanged = useRef<boolean>(false);
  const selectedUser = useRef<Pick<User, 'id' | 'firstname' | 'lastname'>>();

  const filterBtnRef = useRef<HTMLButtonElement>(null);

  const {
    params: { hide_test, community_id, community_name },
    setQueryParam,
    removeQueryParam,
    removeQueryParams,
  } = useQueryParams<{
    search: UserSearchParams;
    hide_test?: 'true';
    community_id: string;
    community_name: string;
  }>();

  const { admin } = useContext(AuthContext);

  const [hideTestCommunities, setHideTestCommunities] = useState(!!hide_test);

  const [selectedCommunity, setSelectedCommunity] = useState<SelectOption>({
    id: community_id,
    value: community_id,
    label: community_name,
  } as SelectOption);

  const [currentUserId, setCurrentUserId] = useState<number>();

  const [deactivateModalOpen, setDeactivateModalOpen] =
    useState<boolean>(false);

  const [communitiesModalOpen, setCommunitiesModalOpen] =
    useState<boolean>(false);

  const history = useHistory();

  const { params, setMultipleQueryParams } = useQueryParams<{
    status: string;
  }>();

  const {
    columnBtnRef,
    columnSettingsOpen,
    closeColumnSettings,
    ColumnSettingsButton,
  } = useColumnSettings();

  const {
    data,
    count,
    totalCount,
    requestError,
    totalPages,
    currentPage,
    search,
    activeColumnSort,
    setCount,
    setCurrentPage,
    setSearch,
    revalidateData,
    setActiveColumnSort,
  } = useTablePagination<TableUser, 'users'>({
    itemsKey: 'users',
    spinnerParentClass: '.kinto-page',
    deps: [hideTestCommunities, selectedCommunity, params],
    mappingFunction: (values: Array<User>) => mapUserData(values),
    apiRequest: ({
      per_page,
      currentPage,
      searchString,
      sort_by,
      sort_direction,
    }) =>
      apiUsers.getUsers({
        per_page,
        page: currentPage,
        search: searchString,
        sort_by,
        sort_direction,
        hide_test: hideTestCommunities,
        status: statusFilterToApiParam(params.status),
        ...(selectedCommunity.value && {
          organisation_id: selectedCommunity.value,
        }),
      }),
  });

  const translationKeys = useMemo(
    () =>
      ({
        id: 'ID',
        name: 'Name',
        email: 'Email',
        lastaccess_at: 'Last active',
        created_at: 'Joined',
        consents: 'Accepted consents',
        organisations_count: 'Communities',
        type: 'User type',
        status: 'Status',
      } as Record<Partial<keyof TableUser>, string>),
    []
  );

  const [handleDeactivateUser, loadingDeactivate] = useCallbackAsync({
    showSpinner: false,
    callback: async (user: TableUser) => {
      setCurrentUserId(user.id);

      const {
        data: { data: status, message: reason },
      } = await apiUsers.getDeactivateStatus(`${user.id}`);

      if (!status.deletable) {
        const reasonMessage = deactivateReasonMessage(user.name, reason);

        await prompts.standard({
          contentIcon: null,
          submitBtnText: 'Ok',
          className: 'deactivate-reason-modal',
          content: <InfoCard text={reasonMessage} />,
        });

        return;
      }

      setDeactivateModalOpen(true);
    },
  });

  const canEdit = useMemo(
    () => !!admin?.permissions.find((p) => p.name === 'users_edit'),
    [admin]
  );

  const currentUser = useMemo(
    () => currentUserId && data.find((user) => user.id === currentUserId),
    [currentUserId, data]
  );

  const filterValues = useMemo(
    () => ({
      status: searchParamsToArray(params.status),
    }),
    [params]
  );

  const activeFiltersCount = useMemo(
    () => filterValues.status?.length || 0,
    [filterValues]
  );

  const tableActions = useMemo(
    () => (user: TableUser) => {
      return user.statusData === 'active' && canEdit ? (
        <TablePageLayout.TableActions
          actions={[
            {
              name: 'Delete',
              icon: 'trash-can',
              variant: 'delete-ghost',
              disabled: loadingDeactivate,
              loading: currentUserId === user?.id && loadingDeactivate,
              onClick: () => handleDeactivateUser(user),
            },
          ]}
        />
      ) : (
        <NoData>No actions</NoData>
      );
    },
    [canEdit, loadingDeactivate, currentUserId, handleDeactivateUser]
  );

  const renderConsents = useCallback(
    (consents: Array<ConsentType>) => (
      <div className="kinto-users__table__consents">
        {!consents.length ? (
          <small className="kinto-users__table__consents__title">
            There are no accepted consents.
          </small>
        ) : (
          consents.map(({ name, description }) => (
            <StatusElement<INameExtended>
              key={uniqueId(name)}
              status="canceled"
              icon="info-circle"
              enableTooltip
              tooltipText={description}
            >
              {name}
            </StatusElement>
          ))
        )}
      </div>
    ),
    []
  );

  const renderNumOfCommunities = useCallback(
    (
      numOfCommunities: number,
      user: Pick<User, 'id' | 'firstname' | 'lastname'>
    ) => (
      <Tooltip
        placement="top-start"
        content={
          selectedCommunity?.value
            ? 'Selected community. Click to view details'
            : 'Number of communities. Click to view details'
        }
      >
        <div className="kinto-users__table__communities">
          <Button
            className="num-of-communities"
            icon={<Icon name="buildings" />}
            iconPosition="right"
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              selectedUser.current = { ...user };
              setCommunitiesModalOpen(true);
            }}
          >
            <span>{numOfCommunities}</span>
          </Button>
        </div>
      </Tooltip>
    ),
    [selectedCommunity]
  );

  const timeDiff = useCallback((lastaccess_at: string): string => {
    const now = dayjs();

    const lastActive = dayjs(lastaccess_at);

    const daysDiff = now.date() - lastActive.date();
    const monthsDiff = now.month() - lastActive.month();
    const yearsDiff = now.year() - lastActive.year();

    if (!monthsDiff && !yearsDiff) {
      if (!daysDiff) {
        return '(today)';
      }
      return `(${daysDiff}d ago)`;
    }

    let years = yearsDiff ? yearsDiff + 'y' : '';

    let months = monthsDiff ? Math.abs(monthsDiff) + 'mo' : '';

    let days = daysDiff ? Math.abs(daysDiff) + 'd' : '';

    if (yearsDiff >= 0 && monthsDiff >= 0 && daysDiff >= 0) {
      return `(${[years, months, days].filter(Boolean).join(', ')} ago)`;
    }

    if (
      (yearsDiff > 0 && monthsDiff < 0) ||
      (yearsDiff > 0 && monthsDiff === 0 && daysDiff < 0)
    ) {
      years = yearsDiff === 1 ? '' : yearsDiff - 1 + 'y';
    }

    if (monthsDiff < 0 || (monthsDiff === 0 && daysDiff < 0)) {
      months = `${(daysDiff < 0 ? 11 : 12) + monthsDiff}mo`;
    }

    if (daysDiff < 0 && monthsDiff > 0) {
      months = monthsDiff === 1 ? '' : monthsDiff - 1 + 'mo';
    }

    if (daysDiff !== 0) {
      days = `${now.diff(
        lastActive
          .add(daysDiff < 0 ? monthsDiff - 1 : monthsDiff, 'month')
          .add(yearsDiff, 'year'),
        'days'
      )}d`;
    }

    return `(${[years, months, days].filter(Boolean).join(', ')} ago)`;
  }, []);

  const mapUserData = useCallback(
    (users: User[]) =>
      users.map(
        ({
          id,
          firstname,
          lastname,
          email,
          lastaccess_at,
          created_at,
          consents,
          organisations_count,
          status,
          type,
        }: User) =>
          ({
            id,
            name: `${firstname} ${lastname}`.trim() || '-',
            email,
            type,
            lastaccess_at: lastaccess_at
              ? `${dayjs(lastaccess_at).format(dateFormat)} ${timeDiff(
                  lastaccess_at
                )}`
              : '-',
            created_at: dayjs(created_at).format(dateFormat),
            organisations_count: renderNumOfCommunities(organisations_count, {
              id,
              firstname,
              lastname,
            }),
            consents: renderConsents(getConsentsFormat(consents)),
            status: (
              <StatusElement status={STATUS_MAP[status]}>
                {STATUS_TRANSLATIONS[status]}
              </StatusElement>
            ),
            statusData: status,
          } as TableUser)
      ),
    [renderConsents, renderNumOfCommunities, timeDiff]
  );

  const handleOnColumnSort = useCallback(
    (sortOptions: SortOption<TableUser>) => {
      setActiveColumnSort(sortOptions);
      setCurrentPage(1);
    },
    [setActiveColumnSort, setCurrentPage]
  );

  useEffectSkipFirst(() => {
    setHideTestCommunities(!!hide_test);
    setCurrentPage(1);
  }, [hide_test]);

  const generateXLSX = useCallback(async () => {
    if (!data || !platformId) {
      return;
    }

    showOverlay('body', 'fixed');

    const {
      data: {
        data: { consents },
      },
    } = await apiPlatform.getConsents(platformId);

    const {
      data: {
        data: { users: allUsers },
      },
    } = await apiUsers.getUsers({
      all_users: '1',
      search: search,
      sort_by: activeColumnSort.sortBy,
      sort_direction: activeColumnSort.sortDirection,
      hide_test: hideTestCommunities,
      status: statusFilterToApiParam(params.status),
      ...(selectedCommunity && {
        organisation_id: selectedCommunity.id,
      }),
    });

    const book = utils.book_new();

    const sheetName = 'Users';

    book.Props = {
      Title: 'Users',
      Author: 'Kinto',
      CreatedDate: new Date(),
    };

    book.SheetNames.push(sheetName);

    const xlsxData = [];

    const userObjectKeys = Object.keys(data[0]);

    // used to filter out user object keys, difference between acquired object and desired xlsx data
    const userObjectKeysToDisplay = Object.keys(translationKeys);

    const finalKeysToDisplay = userObjectKeys.filter(
      (el) => el !== 'consents' && userObjectKeysToDisplay.includes(el)
    );

    // prefill first row in table, only "Consents" label above all consents
    xlsxData.push(
      finalKeysToDisplay.map(() => '').concat(translationKeys['consents'])
    );

    // fill second row with data labels, consents come last
    xlsxData.push(
      finalKeysToDisplay
        .map((el) => translationKeys[el as Partial<keyof TableUser>])
        .concat(consents.map((c) => c.id.toUpperCase()))
    );

    allUsers.forEach(
      ({
        id,
        firstname,
        lastname,
        email,
        type,
        lastaccess_at,
        created_at,
        organisations_count,
        consents: userConsents,
        status,
      }) =>
        xlsxData.push(
          [
            id,
            [firstname, lastname].join(' ').trim() || '-',
            email,
            type,
            lastaccess_at ? dayjs(lastaccess_at).format(dateFormat) : '-',
            dayjs(created_at).format(dateFormat),
            organisations_count,
            status,
          ].concat(
            consents.map((c) =>
              userConsents[c.id as any] ? 'Accepted' : 'Not accepted'
            )
          )
        )
    );

    // BOOK CREATED

    const sheet = utils.aoa_to_sheet(xlsxData);
    const wscols = [
      { wch: 10 },
      { wch: 20 },
      { wch: 40 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ].concat(consents.map(() => ({ wch: 20 })));

    sheet['!cols'] = wscols;

    book.Sheets[sheetName] = sheet;

    // TODO: merge cells depending on number of consents. API needed!
    const merge = [{ s: { r: 0, c: 8 }, e: { r: 0, c: 7 + consents.length } }];

    book.Sheets[sheetName]['!merges'] = merge;

    const bookout = write(book, { bookType: 'xlsx', type: 'binary' });

    saveAs(
      new Blob([stringToArrayBuffer(bookout)], {
        type: 'application/octet-stream',
      }),
      `users_${dayjs().format(dateFormat)}.xlsx`
    );

    hideOverlay('body');
  }, [
    data,
    platformId,
    showOverlay,
    search,
    activeColumnSort.sortBy,
    activeColumnSort.sortDirection,
    hideTestCommunities,
    params.status,
    selectedCommunity,
    translationKeys,
    hideOverlay,
  ]);

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const onSubmit = useCallback(
    async ({ status }: any) => {
      setFiltersModalOpen(false);
      setMultipleQueryParams({ status });
    },
    [setMultipleQueryParams]
  );

  return requestError ? (
    <NoPermissionsPlaceholder />
  ) : (
    <TablePageLayout.PageLayoutContainer className="kinto-page">
      <Heading
        level="1"
        color={getColor('--PRIMARY_1_1')}
        className="kinto-page__header"
      >
        Users
      </Heading>

      <div className="kinto-page__actions">
        <div className="kinto-page__actions__right-side">
          <Input
            placeholder="Search here..."
            value={search}
            prefixIcon={<Icon name="search" />}
            validators={inputValidation}
            {...(search && {
              suffixIcon: (
                <Button
                  variant="ghost"
                  aria-label="Delete input search value"
                  onClick={() => setSearch('')}
                  icon={<Icon name="xmark" />}
                />
              ),
            })}
            onChange={(value, error) => {
              setSearch(value, error);
              setCurrentPage(1);
            }}
          />

          <CommunitySearch
            className="kinto-page__actions__community-filter"
            selectedCommunity={selectedCommunity}
            onSelect={(opt) => {
              setCurrentPage(1);
              setSelectedCommunity(opt);
              setQueryParam('community_id', opt.value);
              setQueryParam('community_name', opt.label);
            }}
            onClear={() => {
              setCurrentPage(1);
              setSelectedCommunity({} as SelectOption);
              removeQueryParams(['community_id', 'community_name']);
            }}
          />

          <Button
            onClick={() => history.push(appUri.USERS_TO_COMMUNITY)}
            variant="outline"
          >
            Users to Community
          </Button>

          <Button
            onClick={() => history.push(appUri.USERS_FEEDBACK)}
            variant="outline"
          >
            User feedback
          </Button>

          <Button
            onClick={generateXLSX}
            disabled={data.length === 0}
            variant="outline"
          >
            Export to XLSX
          </Button>

          <Switch
            className="kinto-page__actions__right-side__toggle"
            label="Hide test users"
            value={hide_test === 'true'}
            onChange={() =>
              !hide_test
                ? setQueryParam('hide_test', 'true')
                : removeQueryParam('hide_test')
            }
          />

          <FiltersButton
            activeFiltersCount={activeFiltersCount}
            onClick={() => setFiltersModalOpen(true)}
            buttonRef={filterBtnRef}
          />
        </div>

        {data.length !== 0 && <ColumnSettingsButton />}
      </div>

      {data.length === 0 ? (
        <div className="kinto-page__empty-state">
          Sorry, there are no results that match your search.
        </div>
      ) : (
        <Table<TableUser>
          expandable
          cacheColumns
          tableId="users-table"
          className="kinto-users__table"
          breakAtMaxWidth={1200}
          tableData={data}
          translationKeys={translationKeys}
          initialSort={activeColumnSort}
          columnSettingsOpen={columnSettingsOpen}
          columnsBtnElement={columnBtnRef.current!}
          columnsModalLabels={COLUMNS_SETTINGS_LABELS}
          tableActions={tableActions}
          excludeColumns={['statusData']}
          excludeSortColumns={['consents', 'status']}
          paginationData={{
            currentPage,
            limit: count,
            totalCount,
            totalPages,
          }}
          goToPageInputProps={{ placeholder: 'Go to page' }}
          onPageChange={setCurrentPage}
          onLimitChange={(data: SelectOption) => {
            setCurrentPage(1);
            setCount(+data.value);
          }}
          onColumnSortClicked={handleOnColumnSort}
          onColumnsModalClose={closeColumnSettings}
        />
      )}

      {communitiesModalOpen && (
        <UserCommunitiesModal
          user={selectedUser.current}
          communityId={selectedCommunity.value}
          onClose={() => {
            setCommunitiesModalOpen(false);

            if (permissionsChanged.current && selectedCommunity.value) {
              permissionsChanged.current = false;
              revalidateData();
            }
          }}
          onPermissionChange={() => {
            permissionsChanged.current = true;
          }}
        />
      )}

      {deactivateModalOpen && currentUser && (
        <DeactivateModal
          userName={currentUser.name}
          userId={`${currentUser.id}`}
          onDeactivate={revalidateData}
          onClose={() => setDeactivateModalOpen(false)}
        />
      )}

      {filtersModalOpen && (
        <FiltersModal
          onClose={() => setFiltersModalOpen(false)}
          filters={USERS_FILTERS}
          onSubmit={onSubmit}
          triggerRef={filterBtnRef.current!}
          initialData={filterValues}
        />
      )}
    </TablePageLayout.PageLayoutContainer>
  );
};

export default Users;
