import { ReactNode } from 'react';
import { SortDirections } from '@faxi/web-component-library';
import { ConsentType, TableUser } from './TableData';
import { CommunityStatus } from './Community';

export type UserRole = 'superadmin' | 'user';
export type AccountStatus =
  | 'active'
  | 'deactivated'
  | 'deleted'

export type User = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  created_at: string;
  lastaccess_at?: string;
  consents: Array<ConsentType>;
  organisations_count: number;
  type: string;
  status: AccountStatus;
  platform: {
    id: number;
    name: string;
  };
};

export type GetUsersProps = {
  per_page?: number;
  page?: number;
  search?: string;
  sort_by?: keyof TableUser;
  sort_direction?: SortDirections;
  hide_test?: boolean;
  all_users?: '1';
};

export type UserFeedback = {
  id: number;
  feedback: string;
  created_at: string;
  deleted_at: string;
};

export type UserOrganisation = {
  id: number;
  name: string;
  status: UserStatus | ReactNode;
  statusType?: UserStatus;
  type: 'admin' | 'regular';
};

export const USER_STATUS = {
  APPROVED: 'Y',
  PENDING: 'N',
  REJECTED: 'R',
  DEACTIVATED: 'D',
  COMMUNITY_DEACTIVATED: 'XY',
} as const;

export type UserStatus = (typeof USER_STATUS)[keyof typeof USER_STATUS];

export type UserOrganisationStatusToCommunity =
  | 'approved'
  | 'pending'
  | 'rejected'
  | 'deactivated'
  | 'deleted';

type UserTypeToCommunity = 'regular_user' | 'admin';

export type UserToCommunity = {
  id: number;
  user_id: number;
  user_status: AccountStatus;
  user_firstname: string;
  user_lastname: string;
  user_login: string;
  user_email: string;
  user_istest: number;
  user_consents: Array<ConsentType>;
  user_type: UserTypeToCommunity;
  organisation_id: number;
  organisation_status: CommunityStatus;
  organisation_name: string;
  user_organisation_status: UserOrganisationStatusToCommunity;
};
