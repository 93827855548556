import { ReactNode } from 'react';
import { PremiumFeature } from './PremiumFeatures';

export type CommunityPocs = 'A' | 'S' | 'N';
export type VerificationType = 'easy' | 'medium' | 'hard' | 'custom';

export const COMMUNITY_STATUS = {
  ACTIVE: 'active',
  HIDDEN: 'hidden',
  DEACTIVATED: 'deactivated',
  DELETED: 'deleted',
} as const;

export type CommunityStatus =
  (typeof COMMUNITY_STATUS)[keyof typeof COMMUNITY_STATUS];


export type Community = {
  id: number;
  name: string;

  //used on analytics page
  expected_users?: number | null;
  registered_users?: number;
  verification_type?: VerificationType;

  pocs?: CommunityPocs;
  // this is level column
  premium?: 'Free' | 'P001' | 'P002' | 'P003';
  // this is end of premium date
  end_of_premium?: string;
  // not used
  bonus?: string;
  crm?: string;
  postcode?: string;
  carparks_count: number;
  status_value: CommunityStatus;
  status: CommunityStatus | ReactNode;
  premium_features: PremiumFeature[];
  premium_features_element: ReactNode;
};

export type CreateCommunityData = {
  id: number;
  name: string;
  type: 'Group' | 'School';
  test: boolean;
  formatted_address: string;
  lat: number;
  lng: number;

  address?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  admins?: number[];
};

export type CommunityEKG = {
  name: string;
  creation_date: string;
  'date-range': Record<string, { journeys: number; rewards: number }>;
};
