import {
  Button,
  theme,
  useCallbackArrayRef,
  ButtonProps,
  Spinner,
} from '@faxi/web-component-library';
import Icon, { INameExtended } from '../Icon';
import {
  flex,
  flexGap,
  laptop,
  marginChildren,
  size,
} from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

import { MouseEvent } from 'react';
import { phablet } from '@faxi/web-css-utilities';

export const PageLayoutContainer = styled.div`
  ${flex('column', 'flex-start')};
  padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_64};
  overflow: auto;
  background-color: var(--BACKGROUND_2_1);

  ${phablet(css`
    padding: ${theme.sizes.SIZE_20};
  `)};

  .kinto-page {
    &__header {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__actions {
      ${flex('row', 'flex-start', 'flex-start')};
      margin: 0 0 ${theme.sizes.SIZE_20};
      gap: ${theme.sizes.SIZE_16};

      ${laptop(
        css`
          margin-bottom: ${theme.sizes.SIZE_20};
          flex-direction: column;
          align-items: flex-start;
          gap: ${theme.sizes.SIZE_16};
        `
      )};

      &__community-filter {
        .wcl-dropdown__content {
          z-index: 1;
        }
      }

      &__right-side {
        ${flex('row')};

        align-items: flex-start;
        flex-wrap: wrap;
        gap: ${theme.sizes.SIZE_16};

        .input,
        .wcl-select {
          min-width: clamp(
            ${theme.sizes.SIZE_208},
            100%,
            ${theme.sizes.SIZE_240}
          );
        }

        ${phablet(
          css`
            margin-bottom: ${theme.sizes.SIZE_20};
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            gap: ${theme.sizes.SIZE_16};
          `
        )};
      }

      &__warning_info {
        ${flex('row', 'center')};
        color: var(--ALERT_WARNING_1_1);
        font-weight: 600;
        padding: ${theme.sizes.SIZE_12} 0;

        > :first-child {
          margin-right: ${theme.sizes.SIZE_8};
          margin-top: ${theme.sizes.SIZE_2};
        }
      }
    }

    &__col-settings {
      height: unset;
      min-height: unset;
      margin-left: auto;
      align-self: flex-end;
    }

    &__search-param {
      margin-bottom: ${theme.sizes.SIZE_20};
    }

    &__empty-state {
      height: 100%;
      color: var(--SHADE_1_3);
    }

    &__breadcrumbs {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__copy-to-clipboard {
      ${flex('row', 'space-between', 'center')};
      width: fit-content;
      ${marginChildren(`0 ${theme.sizes.SIZE_20} 0 0`)};

      > button {
        background: unset;
        border: unset;
        padding: unset;

        > .wcl-icon {
          cursor: pointer;
        }
      }

      > span {
        width: ${theme.sizes.SIZE_264};
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .wcl-table-container {
    flex: 0 0 fit-content;

    .wcl-table-page-selector {
      margin-top: unset;
    }

    .wcl-table {
      &__expander {
        .expander__content {
          padding: ${theme.sizes.SIZE_32} ${theme.sizes.SIZE_16};
        }
      }
    }

    .kinto-journeys__table__tooltip {
      ${flex('row')};
      ${flexGap(theme.sizes.SIZE_8, 'row')};

      .wcl-icon {
        ${size(theme.sizes.SIZE_16)};

        margin: auto 0;
        color: var(--ALERT_ERROR_1_1);

        > svg {
          ${size('100%')};
        }
      }
    }

    .kinto-journeys__table__journey-report-button {
      min-height: unset;
      min-width: ${theme.sizes.SIZE_72};

      &,
      button {
        padding: ${theme.sizes.SIZE_8};

        i:nth-child(2) {
          margin-left: ${theme.sizes.SIZE_10};
        }
      }
    }

    &.kinto-journeys__table {
      .wcl-table-page-selector {
        margin-top: unset;
      }
    }

    &.kinto-communities__table {
      .transport-mode {
        ${flex('row')};
        ${flexGap(theme.sizes.SIZE_12, 'row')};
        color: var(--PRIMARY_1_1);
      }
    }

    .kinto-users__table__consents {
      ${flex('row', 'flex-start', 'center')};

      gap: 10px;
      flex-wrap: wrap;

      .kinto-status-element {
        ${size('auto', '36px')};
      }

      &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ${phablet(css`
        justify-content: flex-end;
      `)}
    }

    .kinto-users__table__communities {
      ${flex('row')};
      ${size('fit-content', 'auto')};

      .button {
        min-width: clamp(${theme.sizes.SIZE_64}, 100%, 100%);
        padding: 0 ${theme.sizes.SIZE_10};

        &.num-of-communities {
          ${flex('row', 'flex-start', 'center')};
          ${flexGap(theme.sizes.SIZE_12, 'row')};
          ${size('auto', '36px')};

          min-height: unset;
        }
      }
    }
  }

  .kinto-users-feedback {
    &__breadcrumbs {
      margin-bottom: ${theme.sizes.SIZE_32};
    }

    &__table {
      td {
        &:first-of-type {
          vertical-align: baseline;
        }
      }

      th {
        &:nth-child(1) {
          min-width: ${theme.sizes.SIZE_104};
        }

        &:nth-child(2) {
          min-width: ${theme.sizes.SIZE_208};
        }

        &:nth-child(3) {
          width: 100%;
        }
      }
    }

    &__title {
      ${flex('row', 'space-between', 'baseline')};
    }
  }

  &.kinto-carpark-spaces-container {
    background-color: var(--ACCENT_1_7);
    height: 100%;
  }

  ${laptop(
    css`
      .kinto-journeys {
        &__search-param {
          .wcl-radio-group__container {
            flex-direction: column;
          }
        }
      }
    `
  )};
`;

export const ActionsContainer = styled.div`
  ${flex('row', 'flex-start', 'center')};
  ${size('100%', theme.sizes.SIZE_48)};
  ${flexGap(theme.sizes.SIZE_64, 'row')};

  .spinner {
    margin-left: ${theme.sizes.SIZE_12};
  }
`;

export type TableAction = {
  name: string;
  loading?: boolean;
  icon: INameExtended;
  onClick?: (
    el?: HTMLButtonElement,
    event?: MouseEvent<HTMLButtonElement>
  ) => void;
} & Pick<ButtonProps, 'variant' | 'iconPosition' | 'disabled'>;

type TableActionsProps = {
  actions: TableAction[];
};

export const TableActions: React.FC<TableActionsProps> = ({ actions }) => {
  const [btnRefs] = useCallbackArrayRef<HTMLButtonElement>(actions.length);

  return (
    <ActionsContainer>
      {actions.map(
        (
          { loading, name, icon, variant = 'ghost', onClick, ...rest },
          index
        ) => (
          <Button
            {...rest}
            ref={btnRefs[index]}
            key={name}
            type="button"
            icon={<Icon name={icon} />}
            variant={variant}
            onClick={(event) =>
              onClick?.(btnRefs[index].current as HTMLButtonElement, event)
            }
          >
            {name}
            {loading && <Spinner size={24} color="inherit" />}
          </Button>
        )
      )}
    </ActionsContainer>
  );
};
